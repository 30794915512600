import Image from 'next/image';
import Link from 'next/link';
import Logo from '../../../public/images/logo.svg';
import WhiteLogo from '../../../public/images/logo-white.svg';
import { v4 as uuidv4 } from 'uuid';
import { useRouter } from 'next/router';
import { isChildrenPageActive, isParentPageActive } from '../../utils/daynamicNavigation';
import { useEffect, useState } from 'react';

export default function Header() {
  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(null);

  // window resize
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });

  const route = useRouter();
  /* -------------------------------------------------------------------------- */
  /*                            daynamic navigations                            */
  /* -------------------------------------------------------------------------- */

  const product = {
    id: 2,
    name: 'Product',
    pages: [
      {
        id: uuidv4(),
        name: 'Alchemis Token Tool',
        description: 'Simplify your token management journey',
        path: '/product/tokentool',
        icon: 'token-tool',
      },
      {
        id: uuidv4(),
        name: 'Card by Alchemis',
        description: 'Earn up to 1% cashback every time you spend crypto',
        path: '/product/crypto-prepare-card',
        icon: 'card',
      },
      {
        id: uuidv4(),
        name: 'Gift Card',
        description: 'Purchase digital gift card with cryptocurrency',
        path: '#',
        icon: 'gift-card',
        status: 'STAY TUNED',
      }
    ]
  };

  const alchemisLab = {
    id: 3,
    name: 'Alchemis Lab',
    path: '/labs',
    pages: [
      {
        id: uuidv4(),
        name: 'Alchemis Lab',
        description: 'Unleashing Innovation: Your Leading Technology Hub',
        path: '/labs',
        icon: 'alchemis-lab'
      },
      {
        id: uuidv4(),
        name: 'Blockchain Development',
        description: 'Empowering financial inclusion with blockchain solutions',
        path: '/labs/blockchain-development',
        icon: 'blockchain-development'
      },
      {
        id: uuidv4(),
        name: 'Smart Contract Development',
        description: 'Solutions for various use cases like ICOs, DeFi platforms, and dApps',
        path: '/labs/smart-contract-development',
        icon: 'smart-contract'
      },
      {
        id: uuidv4(),
        name: 'dApp Development',
        description: 'Create safe, scalable, and efficient dApps on blockchain platforms',
        path: '/labs/dapp-development',
        icon: 'dapp'
      },
      {
        id: uuidv4(),
        name: 'Blockchain Consulting',
        description: 'Identify the best blockchain applications and strategies',
        path: '/labs/blockchain-consulting',
        icon: 'blockchain-consulting'
      },
      {
        id: uuidv4(),
        name: 'Tokenization Services',
        description: 'End-to-end tokenomics support for successful launches',
        path: '/labs/tokenization-services',
        icon: 'tokenization'
      },
      {
        id: uuidv4(),
        name: 'Non-Fungible Tokens',
        description: 'Tokenize assets for digital ownership',
        path: '/labs/non-fungible-tokens',
        icon: 'non-fungible'
      }
    ]
  };


  const resource = {
    id: 4,
    name: 'Resources',
    pages: [
      {
        id: uuidv4(),
        name: 'About Alchemis',
        description: 'Explore more about us. Unlocking possibilities through innovative solutions.',
        path: '/about',
        newTab: false,
        icon: 'about'
      },
      // {
      //   id: uuidv4(),
      //   name: 'Blog',
      //   description: 'Stay updated with our latest news and developments',
      //   path: '/blog',
      //   newTab: false,
      //   icon: 'blog'
      // },
      {
        id: uuidv4(),
        name: 'Career',
        description: 'Find your dream job',
        path: '/careers',
        newTab: false,
        icon: 'career'
      },
      {
        id: uuidv4(),
        name: 'Documentation',
        description: 'Seamless implementation and usage',
        path: 'https://tokentool-docs.alchemis.com/',
        newTab: true,
        icon: 'documentation'
      }
    ]
  };

  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };

  const renderDropdownItem = (page) => {
    return (
      <li key={page?.id} onClick={() => setToggle(false)} className={'group/item'}>
        <Link href={page?.path ?? '#'} legacyBehavior>
          {page?.newTab ? (
            <a
              target='_blank'
              rel='noopener noreferrer'
              className='bg-transparent focus:text-accent flex rounded-xl px-5 py-2 transition-colors'>
              <div className='min-w-[20px] flex'>
                <svg className={`icon h-5 w-5 group-hover/item:fill-accent dark:group-hover/item:fill-accent ${isChildrenPageActive(page?.path, route.asPath)
                  ? 'fill-accent'
                  : 'fill-[#E7E8EC]'
                }`}>
                  <use xlinkHref={`/images/header/icon.svg#${page?.icon}`}/>
                </svg>
              </div>
              <div className={'flex flex-col flex-wrap pl-2 min-w-[280px] whitespace-pre-wrap gap-2'}>
                <div className='flex'>
                  <span
                    className={`font-display group-hover/item:text-accent dark:group-hover/item:text-accent ${
                      isChildrenPageActive(page?.path, route.asPath)
                        ? '!text-accent !dark:text-accent'
                        : 'text-jacarta-700 dark:text-white'
                    } text-sm`}
                  >
                    {page?.name}
                  </span>
                  {page?.status && (
                    <div
                      className='flex border rounded-sm border-accent text-accent text-[10px] px-2 ml-2 items-center'><span>{page?.status}</span></div>
                  )}
                </div>
                <div
                  className={`text-jacarta-400 text-xs`}
                >
                  {page?.description}
                </div>
              </div>
            </a>
          ) : (
            <a
              className='dark:hover:bg-transparent hover:text-accent dark:hover:text-accent focus:text-accent hover:bg-jacarta-50 flex rounded-xl px-5 py-2 transition-colors'>
              <div className='min-w-[20px] flex'>
                <svg className={`icon h-5 w-5 group-hover/item:fill-accent dark:group-hover/item:fill-accent ${isChildrenPageActive(page?.path, route.asPath)
                  ? '!fill-accent'
                  : 'fill-[#E7E8EC]'
                }`}>
                  <use xlinkHref={`/images/header/icon.svg#${page?.icon}`}/>
                </svg>
              </div>
              <div className={'flex flex-col flex-wrap pl-2 min-w-[280px] whitespace-pre-wrap gap-2'}>
                <div className='flex'>
                  <span
                    className={`font-display group-hover/item:text-accent dark:group-hover/item:text-accent ${
                      isChildrenPageActive(page?.path, route.asPath)
                        ? '!text-accent !dark:text-accent'
                        : 'text-jacarta-700 dark:text-white'
                    } text-sm`}
                  >
                    {page?.name}
                  </span>
                  {page?.status && (
                    <div
                      className='flex border rounded-sm border-accent text-accent text-[10px] px-2 ml-2 items-center'><span>{page?.status}</span></div>
                  )}
                </div>
                <div
                  className={`text-jacarta-400 text-xs`}
                >
                  {page?.description}
                </div>
              </div>
            </a>
          )}
        </Link>
      </li>
    );
  };

  return (
    <>
      {/* main desktop menu sart*/}
      <header className='js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors'>
        <div className='flex items-center px-6 py-6 xl:px-24 '>
          <Link className='shrink-0' href='/' legacyBehavior>
            <a>
              <div className='dark:hidden'>
                <Image
                  src={Logo}
                  height={28}
                  width={130}
                  alt='Alchemis | NFT Marketplace'
                  className='max-h-7 h-auto '
                />
              </div>
              <div className='hidden dark:block'>
                <Image
                  src={WhiteLogo}
                  height={28}
                  width={130}
                  alt='Alchemis | NFT Marketplace'
                />
              </div>
            </a>
          </Link>
          {/* End  logo */}

          <div
            className='js-mobile-menu invisible fixed inset-0 z-10 ml-auto items-center opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent'>
            <nav className='navbar w-full'>
              <ul className='flex flex-col lg:flex-row'>
                {/* Home */}
                <Link href='/' legacyBehavior>
                  <a>
                    <button
                      className='font-display hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base lg:text-white text-jacarta-700 dark:text-white lg:px-5'>
                        <span
                          className={
                            isChildrenPageActive(route.asPath, '/')
                              ? 'text-accent dark:text-accent'
                              : ''
                          }
                        >
                          Home
                        </span>
                    </button>
                  </a>
                </Link>

                {/* product */}
                <li className='js-nav-dropdown group relative'>
                  <button
                    className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'>
                    <span
                      className={
                        isParentPageActive(product.pages, route.asPath)
                          ? 'text-accent dark:text-accent'
                          : ''
                      }
                    >
                      Product
                    </span>
                    <i className='lg:hidden'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width={24}
                        height={24}
                        className='h-4 w-4 dark:fill-white'
                      >
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                      </svg>
                    </i>
                  </button>
                  <ul
                    className='dropdown-menu dark:bg-jacarta-800 left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative'
                    aria-labselledby='navDropdown-4'
                  >
                    {product?.pages?.map?.((page) => (
                      renderDropdownItem(page)
                    ))}
                  </ul>
                </li>

                {/* alchemis lab */}
                <Link href='/labs' legacyBehavior>
                  <a>
                    <button
                      className='font-display hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base lg:text-white text-jacarta-700 dark:text-white lg:px-5'>
                        <span
                          className={
                            isChildrenPageActive(route.asPath, '/labs')
                              ? 'text-accent dark:text-accent'
                              : ''
                          }
                        >
                          Alchemis Lab
                        </span>
                    </button>
                  </a>
                </Link>
                {/*<li className='js-nav-dropdown group/main relative'>*/}
                {/*  <a*/}
                {/*    href={alchemisLab.path}*/}
                {/*    className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'>*/}
                {/*      <span*/}
                {/*        className={isParentPageActive(alchemisLab.pages, route.asPath) ? 'text-accent dark:text-accent' : ''}>*/}
                {/*        Alchemis Lab*/}
                {/*      </span>*/}
                {/*    <i className='lg:hidden'>*/}
                {/*      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={24} height={24}*/}
                {/*           className='h-4 w-4 dark:fill-white'>*/}
                {/*        <path fill='none' d='M0 0h24v24H0z' />*/}
                {/*        <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />*/}
                {/*      </svg>*/}
                {/*    </i>*/}
                {/*  </a>*/}
                {/*  <ul*/}
                {/*    className='dropdown-menu dark:bg-jacarta-800 left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl transition-all will-change-transform group-hover/main:visible group-hover/main:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover/main:translate-y-2 relative'>*/}
                {/*    {alchemisLab?.pages?.map?.((page) => (*/}
                {/*      renderDropdownItem(page)*/}
                {/*    ))}*/}
                {/*  </ul>*/}
                {/*</li>*/}

                {/* resource */}
                <li className='js-nav-dropdown group relative'>
                  <button
                    className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'>
                    <span
                      className={
                        isParentPageActive(resource.pages, route.asPath)
                          ? 'text-accent dark:text-accent'
                          : ''
                      }
                    >
                      Resources
                    </span>
                    <i className='lg:hidden'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width={24}
                        height={24}
                        className='h-4 w-4 dark:fill-white'
                      >
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                      </svg>
                    </i>
                  </button>
                  <ul
                    className='dropdown-menu dark:bg-jacarta-800 left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative'
                    aria-labelledby='navDropdown-4'
                  >
                    {resource?.pages?.map?.((page) => (
                      renderDropdownItem(page)
                    ))}
                  </ul>
                </li>

                {/* contact us */}
                <li className='js-nav-dropdown group relative'>
                  <Link href='/contact' legacyBehavior>
                    <a>
                      <button
                        className='font-display hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base lg:text-white text-jacarta-700 dark:text-white lg:px-5'>
                        <span
                          className={
                            isChildrenPageActive(route.asPath, '/contact')
                              ? 'text-accent dark:text-accent'
                              : ''
                          }
                        >
                          Contact
                        </span>
                      </button>
                    </a>
                  </Link>
                </li>
              </ul>
            </nav>
            {/* End menu for desktop */}

            <div className='ml-8 hidden items-center lg:flex xl:ml-12'>
              <div className='hidden justify-end lg:flex'>
                {/* <!-- CTA --> */}
                <Link href='https://tokentool.alchemis.com' legacyBehavior>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    className='rounded-full py-3 px-8 bg-white text-center font-semibold whitespace-nowrap text-accent transition-all hover:bg-primary hover:text-white'>
                    {' '}
                    Token Tool
                  </a>
                </Link>
              </div>
            </div>
            {/* End header right content (metamask and other) for desktop */}
          </div>
          {/* header menu conent end for desktop */}

          <div className='ml-auto flex lg:hidden'>
            <div className='hidden justify-end lg:flex'>
              {/* <!-- CTA --> */}
              <Link href='/collection/explore_collection' legacyBehavior>
                <a
                  className='rounded-full py-3 px-8 text-center whitespace-nowrap font-semibold text-accent transition-all hover:bg-accent-dark hover:text-white'>
                  {' '}
                  Start
                </a>
              </Link>
            </div>
            <button
              className='js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border transition-colors hover:border-transparent focus:border-transparent dark:border-transparent'
              aria-label='open mobile menu'
              onClick={() => setToggle(true)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width={24}
                height={24}
                className='fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
              >
                <path fill='none' d='M0 0h24v24H0z' />
                <path d='M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z' />
              </svg>
            </button>
          </div>
          {/* End header right content  for mobile */}
        </div>
        {/* End flex item */}
      </header>
      {/* main desktop menu end */}

      {/* start mobile menu and it's other materials  */}
      <div
        className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
          toggle ? 'nav-menu--is-open' : 'hidden'
        }`}
      >
        <div
          className='t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between p-6 lg:hidden'>
          <div className='dark:hidden'>
            <Image
              src={Logo}
              height={28}
              width={130}
              alt='Alchemis | NFT Marketplace'
              className='max-h-7 h-auto '
            />
          </div>

          <div className='hidden dark:block'>
            <Image
              src={WhiteLogo}
              height={28}
              width={130}
              alt='Alchemis | NFT Marketplace'
            />
          </div>

          <button
            className='js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border transition-colors hover:border-transparent focus:border-transparent dark:border-transparent'
            onClick={() => setToggle(false)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              width={24}
              height={24}
              className='fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
            >
              <path fill='none' d='M0 0h24v24H0z' />
              <path
                d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z' />
            </svg>
          </button>
        </div>
        {/* mobile menu top header content */}

        <nav className='navbar w-full mt-24'>
          <ul className='flex flex-col lg:flex-row'>
            <li className='group' onClick={() => setToggle(false)}>
              <Link href='/' legacyBehavior>
                <a>
                  <button
                    className='text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5'>
                    <span
                      className={
                        isChildrenPageActive('/', route.asPath)
                          ? 'text-accent dark:text-accent'
                          : ''
                      }
                    >
                      Home
                    </span>
                  </button>
                </a>
              </Link>
            </li>
            <li className='js-nav-dropdown group relative'>
              <button
                onClick={() => mobileCollapse(product.id)}
                className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'
              >
                <span
                  className={
                    isParentPageActive(product.pages, route.asPath)
                      ? 'text-accent dark:text-accent'
                      : ''
                  }
                >
                  {product.name}
                </span>
                <i className='lg:hidden'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width={24}
                    height={24}
                    className='h-4 w-4 dark:fill-white'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                  </svg>
                </i>
              </button>
              <ul
                className={`dropdown-menu left-0 top-[85%] z-10 grid-flow-row grid-cols-[repeat(2,_1fr)] gap-x-4 whitespace-nowrap rounded-xl transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative ${
                  isCollapse === product.id ? 'block' : 'hidden'
                }`}
              >
                {product?.pages?.map((page) => (
                  renderDropdownItem(page)
                ))}
              </ul>
            </li>
            <li className='group' onClick={() => setToggle(false)}>
              <Link href='/labs' legacyBehavior>
                <a>
                  <button
                    className='font-display hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base lg:text-white text-jacarta-700 dark:text-white lg:px-5'>
                    <span
                      className={
                        isChildrenPageActive(route.asPath, '/labs')
                          ? 'text-accent dark:text-accent'
                          : ''
                      }
                    >
                      Alchemis Lab
                    </span>
                  </button>
                </a>
              </Link>
            </li>
            {/*<li className='js-nav-dropdown nav-item dropdown group relative'>*/}
            {/*  <button*/}
            {/*    onClick={() => mobileCollapse(alchemisLab.id)}*/}
            {/*    className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'*/}
            {/*  >*/}
            {/*    <span*/}
            {/*      className={*/}
            {/*        isParentPageActive(alchemisLab.pages, route.asPath)*/}
            {/*          ? 'text-accent dark:text-accent'*/}
            {/*          : ''*/}
            {/*      }*/}
            {/*    >*/}
            {/*      {alchemisLab.name}*/}
            {/*    </span>*/}
            {/*    <i className='lg:hidden'>*/}
            {/*      <svg*/}
            {/*        xmlns='http://www.w3.org/2000/svg'*/}
            {/*        viewBox='0 0 24 24'*/}
            {/*        width={24}*/}
            {/*        height={24}*/}
            {/*        className='h-4 w-4 dark:fill-white'*/}
            {/*      >*/}
            {/*        <path fill='none' d='M0 0h24v24H0z' />*/}
            {/*        <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />*/}
            {/*      </svg>*/}
            {/*    </i>*/}
            {/*  </button>*/}
            {/*  <ul*/}
            {/*    className={`dropdown-menu left-0 top-[85%] z-10 grid-flow-row grid-cols-[repeat(2,_1fr)] gap-x-4 whitespace-nowrap rounded-xl transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative ${*/}
            {/*      isCollapse === alchemisLab.id ? 'block' : 'hidden'*/}
            {/*    }`}*/}
            {/*    aria-labelledby='navDropdown-1'*/}
            {/*  >*/}
            {/*    {alchemisLab?.pages?.map((page) => (*/}
            {/*      renderDropdownItem(page)*/}
            {/*    ))}*/}
            {/*  </ul>*/}
            {/*</li>*/}
            <li className='js-nav-dropdown group relative'>
              <button
                onClick={() => mobileCollapse(resource.id)}
                className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'
              >
                <span
                  className={
                    isParentPageActive(resource.pages, route.asPath)
                      ? 'text-accent dark:text-accent'
                      : ''
                  }
                >
                  {resource.name}
                </span>
                <i className='lg:hidden'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width={24}
                    height={24}
                    className='h-4 w-4 dark:fill-white'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                  </svg>
                </i>
              </button>
              <ul
                className={`dropdown-menu left-0 top-[85%] z-10 grid-flow-row grid-cols-[repeat(2,_1fr)] gap-x-4 whitespace-nowrap rounded-xl transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative ${
                  isCollapse === resource.id ? 'block' : 'hidden'
                }`}
                aria-labelledby='navDropdown-4'
              >
                {resource?.pages?.map((page) => (
                  renderDropdownItem(page)
                ))}
              </ul>
            </li>
            <li className='group' onClick={() => setToggle(false)}>
              <Link href='/contact' legacyBehavior>
                <a>
                  <button
                    className='font-display hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base lg:text-white text-jacarta-700 dark:text-white lg:px-5'>
                    <span
                      className={
                        isChildrenPageActive(route.asPath, '/contact')
                          ? 'text-accent dark:text-accent'
                          : ''
                      }
                    >
                      Contact Us
                    </span>
                  </button>
                </a>
              </Link>
            </li>
          </ul>
        </nav>
        <div className="pt-5">
          <Link href='https://tokentool.alchemis.com' legacyBehavior>
            <a
              target='_blank'
              rel='noopener noreferrer'
              className='rounded-full py-2 px-8 bg-white text-center w-full font-semibold whitespace-nowrap text-accent transition-all hover:bg-primary hover:text-white'>
              {' '}
              Token Tool
            </a>
          </Link>
        </div>
        {/* End navbar mobile menu  */}
      </div>
      {/* End mobile menu and it's other materials */}
    </>
  );
}
