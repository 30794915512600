import Link from "next/link";
import { alchemisLabItems, companyItems, tokenToolItems, socialIcons } from "../data/footer_data";

const footer = ({expand}) => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-footer page-footer bg-white" style={{paddingTop: expand ? 148 : 0}}>
        <div className="container">
          <div className="grid grid-cols-6 gap-x-5 gap-y-14 pt-24 pb-12 md:grid-cols-12">
            <div className="col-span-3 md:col-span-4">
              {/* <!-- Logo --> */}
              <Link href="#" legacyBehavior>
                <a className="inline-block">
                  <img
                    src="/images/logo-white.svg"
                    className="h-7 mb-6"
                    alt="Alchemis"
                  />
                </a>
              </Link>
              <p className="dark:text-jacarta-500 mb-6">
                Create, sell and collect truly rare digital artworks. Powered by
                blockchain technology.
              </p>

              {/* <!-- Socials --> */}
              <div className="flex space-x-5">
                {socialIcons.map((item) => {
                  const { id, href, text } = item;
                  return (
                    <Link href={href} key={id} legacyBehavior>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="group cursor-pointer"
                      >
                        <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                          <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                        </svg>
                      </a>
                    </Link>
                  );
                })}
              </div>
            </div>
            <div
              className={`col-span-full sm:col-span-3 md:col-span-3 md:col-start-6`}
            >
              {/*<h3 className="font-display text-jacarta-700 mb-3 text-sm dark:text-white">*/}
              {/*  Alchemis Lab*/}
              {/*</h3>*/}
              {/*<ul className="dark:text-jacarta-500 flex flex-col space-y-1">*/}
              {/*  {alchemisLabItems.map((item) => {*/}
              {/*    const { id, href, text } = item;*/}
              {/*    return (*/}
              {/*      <li key={id}>*/}
              {/*        <Link href={href} legacyBehavior>*/}
              {/*          <a className="hover:text-accent">*/}
              {/*            {text}*/}
              {/*          </a>*/}
              {/*        </Link>*/}
              {/*      </li>*/}
              {/*    );*/}
              {/*  })}*/}
              {/*</ul>*/}
            </div>
            <div
              className={`col-span-full sm:col-span-3 md:col-span-2`}
            >
              <h3 className="font-display text-jacarta-700 mb-3 text-sm dark:text-white">
                Token Tool
              </h3>
              <ul className="dark:text-jacarta-500 flex flex-col space-y-1">
                {tokenToolItems.map((item) => {
                  const { id, href, text } = item;
                  return (
                    <li key={id}>
                      <Link href={href} legacyBehavior>
                        <a className="hover:text-accent">
                          {text}
                        </a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              className={`col-span-full sm:col-span-3 md:col-span-2`}
            >
              <h3 className="font-display text-jacarta-700 mb-3 text-sm dark:text-white">
                Company
              </h3>
              <ul className="dark:text-jacarta-500 flex flex-col space-y-1">
                {companyItems.map((item) => {
                  const { id, href, text } = item;
                  return (
                    <li key={id}>
                      <Link href={href} legacyBehavior>
                        <a className="hover:text-accent">
                          {text}
                        </a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-jacarta-400 text-sm">
              <span>© {new Date().getFullYear()} Alchemis. All Rights Reserved.</span>
            </span>

            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <Link href="/terms" legacyBehavior>
                  <a className="hover:text-accent dark:hover:text-white">
                    Terms and conditions
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/privacy" legacyBehavior>
                  <a className="hover:text-accent dark:hover:text-white">
                    Privacy policy
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
